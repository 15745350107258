export const team = [
  {
    title: 'Founder',
    authorPhoto: {
      src: '/images/dh_profileimage.jpg',
      srcSet: '/images/dh_profileimage-2x.jpg 2x',
    },
    authorName: 'David Hodgetts',
  },
];
