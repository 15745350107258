import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useMediaQuery, Grid } from '@mui/material';
import SVGImage from 'components/atoms/SVGImage/SVGImage';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: 420,
  },
}));

const Story = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid
        container
        justifyContent="space-between"
        spacing={isMd ? 4 : 2}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-start"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <div>
            <SectionHeader
              title="Our story"
              subtitle="The story of VRGeoscience Limited started in 2003. When working with Lidar data at the time I was unable to use the data to its full capacity. There was no software that fulfilled requirements at a reasonable price. I had a long history of developing software from games as a teenager, through to a PhD in numerical modelling and developing algorithms for commercial applications along the way, so I decided to develop an application to meet the requirements of geoscientists working with digital outcrop data. From the outset VRGS was conceived as a commercial product and designed as such even though from an academic base. With the increased use of lidar, and then SFM data, the user base for VRGS started to grow, and in 2016 VRGS went for sale online through the University of Manchester’s online marketplace Click2Go. Over the years the number of commercial and academic licenses grew to a point where VRGS and its customers deserved full time support. In 2020 I decided to take a career break to focus fully on VRGS, and that is where we are now.  
              I am looking forward to working with new and old customers, developing software to enable geoscientists to be more productive, and working towards a better future for us and the environment. 
              Dr David Hodgetts.
               "
              align="left"
              disableGutter
              subtitleProps={{
                color: 'textPrimary',
                variant: 'body1',
              }}
            />
          </div>
        </Grid>
        <Grid
          item
          container
          justifyContent={isMd ? 'flex-end' : 'flex-start'}
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <SVGImage
            src="/images/vrgeoscience_logo.svg"
            alt="Our story"
            className={classes.image}
          />
        </Grid>
      </Grid>
    </div>
  );
};

Story.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Story;
