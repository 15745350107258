import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Grid } from '@mui/material';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';

const WhoWeAre = (props) => {
  const { className, ...rest } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={6} data-aos="fade-up">
          <SectionHeader
            title="Who are we?"
            subtitle="VRGeoscience Limited is a spin out company from the University of Manchester's Innovation Factory. Founded by Dr David Hodgetts, an academic expert in the application of digital outcrop data in subsurface characterisation and a highly experienced software developer, VRGeoscience develops high performance software for 3D geological and geospatial data."
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} data-aos="fade-up">
          <SectionHeader
            title="Our Mission:"
            subtitle="Increase efficiency and reduce environmental impact in natural resource use.
            Enable scientists and engineers to make effective use of geospatial data.
            Facilitate the effective use of geoscientific data through the green energy transition and beyond."
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

WhoWeAre.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default WhoWeAre;
